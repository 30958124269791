<template>
  <div>
    <span @click.prevent="$parent.show_configure_panel = !$parent.show_configure_panel"
      class="is-clickable has-text-link">
      <i class="fas fa-th mr-1"></i>
      Modules
      <i v-if="!$parent.show_configure_panel" class="fas fa-angle-down"></i>
      <i v-else class="fas fa-angle-up"></i>
    </span>
    <div v-if="$parent.show_configure_panel" class="configure-panel">
      <div>
        <span class="has-text-dark">
          {{ $t("panel_block") }}
        </span>
        <span v-if="$parent.is_pannel_empty" class="has-text-grey-light is-size-7 ml-3">
          Make one of the modules visible
        </span>
        <button @click.prevent="$parent.show_configure_panel = !$parent.show_configure_panel"
          class="delete is-small is-pulled-right"></button>
        <div>
          <span v-for="(block, index) in $parent.modules" :key="index" class="tag is-info mr-2 mb-1"
            :class="{ 'is-light': block.is_visible == 0 }">
            {{ block.name }}
            <i v-if="block.is_loading" class="fas fa-spinner fa-spin ml-2"></i>
            <a v-else href="" @click.prevent="$parent.toggle_module(block)" class="has-text-white">
              <i v-if="block.is_visible == 1" class="far fa-eye ml-2"></i>
              <i v-else class="far fa-eye-slash ml-2 has-text-info"></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.configure-panel {
  position: absolute;
  background: #fff;
  z-index: 9999;
  padding: 5px;
  border: solid 1px rgb(118, 135, 150);
  box-shadow: 0px 0px 3px 2px #c5c5c5;
  width: 95%;
}
</style>
